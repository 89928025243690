import axios from "axios";
import url from "../url";
import { getArray, setQuery, getJson, setData } from "../resources/accessLevelResource.js";

export default class AccessLevelRepository {
    async index(data) {
        const response = await axios.get(url("roleList"));
        if (response.status === 200) {
            return response.data;
        }
    }

    async removeRoleFromUser(data) {
        const response = await axios.post(url("removeRoleFromUser"), data);
    }
    async deleteRole(id) {
        const response = await axios.delete(url("deleteRole", { id: id }));
    }
    async userRolesIndex(data, id) {
        const params = setQuery(data);
        // console.log(params);
        let roleId;
        id != undefined ? (roleId = `/${id}`) : (roleId = "");

        const response = await axios.get(`api/admin-users-by-role${roleId}`, { params });
        if (response.status === 200) {
            return getArray(response.data);
        }
    }
    async permissionIndex(data) {
        const response = await axios.get(url("permissionsList"));
        if (response.status === 200) {
            return response.data;
        }
    }
    async show(id) {
        const response = await axios.get(url("showRole", { id: id }));
        if (response.status === 200) {
            return response.data.data;
        }
    }

    async store(data) {
        const response = await axios.post(url("addRoles"), data);
        if (response.status === 201) {
            return response.data;
        }
    }
    async assignRoleToUser(data) {
        const response = await axios.post(url("assignRoleToUser"), data);
        if (response.status === 201) {
            return response.data;
        }
    }

    async assignPermissions(data, id) {
        console.log(data, id);
        const response = await axios.post(url("assignPermissions", { id: id }), data);
        if (response.status === 201) {
            return response.data;
        }
    }
    async update(id, data) {
        // const json = setData(data)
        const response = await axios.put(url("updateGenres", { genre: id }), data);
        if (response.status === 200) {
            return response.data.data;
        }
    }

    async updateSort(playlistId) {
        const response = await axios.put(url("updateSortMoods", { mood: playlistId }));
        if (response.status === 200) {
            return response.data.data;
        }
    }

    async delete(id) {
        const response = await axios.delete(url("deleteGenres", { genre: id }));
        if (response.status === 200) {
            return true;
        }
    }

    async attach(moodId, musicId) {
        const json = { music_id: musicId };
        const response = await axios.post(url("attachMoods", { mood: moodId }), json);
        if (response.status === 200) {
            return response.data.data;
        }
    }

    async detach(moodId, musicId) {
        const json = { music_id: musicId };
        const response = await axios.post(url("detachMoods", { mood: moodId }), json);
        if (response.status === 200) {
            return response.data.data;
        }
    }
}
