import { ref, watch } from "@vue/composition-api";

// Notification
import accessRepository from "@/abstraction/repository/accessLevelRepository";

const repository = new accessRepository();
export default function usemoodList() {
    const refplaylistListTable = ref(null);
    const Roles = ref([]);
    const perissionList = ref();
    const perPage = ref(100);
    const perPageOptions = [10, 25, 50, 100];
    const sortBy = ref("id");
    const currentPage = ref(1);
    const searchQuery = ref("");
    const isSortDirDesc = ref(true);
    const roleFilter = ref(null);
    const planFilter = ref(null);
    const statusFilter = ref(null);
    const pagination = ref({});
    const userRole = ref([]);

    const refetchData = () => {
        refplaylistListTable.value.refresh();
    };

    watch([currentPage, perPage, searchQuery, roleFilter, planFilter, statusFilter], () => {
        refetchData();
    });

    // Table Handlers
    const tableColumns = [{ key: "user_name", sortable: true }, { key: "name", sortable: true }, { key: "role", sortable: true }, { key: "actions" }];

    const roleTableColumns = [{ key: "name", sortable: true }, { key: "actions" }];

    const addRole = async (data) => {
        try {
            return await repository.store(data);
        } catch (e) {
            return false;
        }
    };
    const assignRoleToUser = async (data) => {
        try {
            return await repository.assignRoleToUser(data);
        } catch (e) {
            return false;
        }
    };
    const assignPermissions = async (data, id) => {
        try {
            return await repository.assignPermissions(data, id);
        } catch (e) {
            return false;
        }
    };
    const showPermissions = async (id) => {
        try {
            return await repository.show(id);
        } catch (e) {
            return false;
        }
    };
    const fetchAllPermissions = async () => {
        const response = await repository.permissionIndex();
        perissionList.value = response.data;
        return perissionList;
    };

    const updatePlaylist = async (id, data) => {
        try {
            return await repository.update(id, data);
        } catch (e) {
            return false;
        }
    };

    const fetchRoleList = async () => {
        const RolesList = await repository.index();
        Roles.value = RolesList.data;
        return Roles;
    };

    const deletePlaylist = async (id) => {
        try {
            const response = await repository.delete(id);
            pagination.value.itemsLength--;
            const index = Roles.value.findIndex((x) => x.id === id);
            Roles.value.splice(index, 1);
            return response;
        } catch (e) {
            return false;
        }
    };

    const changePageUserRoles = async (page) => {
        const response = await fetchUserRoles({ pagination: page });
        return response;
    };

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*
    const fetchUserRoles = async (paginate = { page: 1, itemsPerPage: perPage.value }, filters = {}) => {
        const userRoles = await repository.userRolesIndex({ filters, pagination: paginate });
        userRole.value = userRoles.data;
        pagination.value = userRoles.pagination;
        return userRole;
    };
    const removeRoleFromUser = async (data) => {
        const response = await repository.removeRoleFromUser(data);
        return response;
    };
    return {
        tableColumns,
        Roles,
        userRole,
        pagination,
        perPageOptions,
        perPage,
        sortBy,
        currentPage,
        addRole,
        showPermissions,
        updatePlaylist,
        fetchRoleList,
        deletePlaylist,
        perissionList,
        fetchAllPermissions,
        assignPermissions,
        assignRoleToUser,
        fetchUserRoles,
        changePageUserRoles,
        removeRoleFromUser,
        roleTableColumns,
    };
}
